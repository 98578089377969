export const cameraLocationStatus = [
    { name: 'Active', value: 'active' },
    { name: 'Archived', value: 'archive' },
    { name: 'Inactive', value: 'inactive' },
];

export const searchStatuses = [
    { id: 'all', name: 'All' },
    { id: 'input-unprocessed', name: 'Input - Unprocessed' },
    { id: 'input-on-process', name: 'Input - On Process' },
    { id: 'input-rejected', name: 'Input - Rejected' },
    // { id: 'input-processed', name: 'Input - Processed' },
    { id: 'verify-pending', name: 'Verify - Pending' },
    { id: 'verify-on-process', name: 'Verify - On Process' },
    { id: 'verify-rejected', name: 'Verify - Rejected'},
    // { id: 'verify-success', name: 'Verify - Success' },
    // { id: 'verify-processed', name: 'Verify - Processed' },
    { id: 'lto-get-record-failed', name: 'LTO (Get Record) - Failed' },
    { id: 'lto-get-record-with-record', name: 'LTO (Get Record) - With Record' },
    { id: 'validate-pending', name: 'Validate - Pending'},
    { id: 'validate-on-process', name: 'Validate - On Process'},
    { id: 'validate-rejected', name: 'Validate - Rejected'},
    // { id: 'validate-processed', name: 'Validate - Processed'},
    // { id: 'validate-approved', name: 'Validate - Approved'},
    { id: 'lgu-approval-pending', name: 'Approval (LGU) - Pending'},
    { id: 'lgu-approval-on-process', name: 'Approval (LGU) - On Process'},
    // { id: 'lgu-approval-processed', name: 'Approval (LGU) - Processed'},
    { id: 'lgu-approval-approved', name: 'Approval (LGU) - Approved'},
    { id: 'lgu-approval-rejected', name: 'Approval (LGU) - Rejected'},
    { id: 'voided', name: 'Voided'},
    { id: 'warning-ovr', name: 'Warning OVR'},
]

export const permissions = {
    "dashboard": {
        _id: 1,
        text: 'Dashboard'
    },
    "forecast-menu": {
        _id: null,
        text: 'Forecast',
        children: ["financial-forecast", "alarm-efficiency"],
        state: {
            opened: true
        }
    },
    "financial-forecast": {
        _id: 2,
        text: 'Financial Forecast'
    },
    "alarm-efficiency": {
        _id: 3,
        text: 'Alarm Efficiency',
    },
    "general-search-menu": {
        _id: null,
        text: 'General Search',
        children: ["void", "search-and-view", "edit-record", "resend-to-lto", "edit-mv-file-records", "edit-violation", "timeline"],
        state: {
            opened: true
        }
    },
    "void": {
        _id: 4,
        text: 'Void'
    },
    "search-and-view": {
        _id: 5,
        text: 'Search and View'
    },
    "edit-record": {
        _id: 6,
        text: "Edit Record"
    },
    "resend-to-lto": {
        _id: 7,
        text: "Resend to LTO"
    },
    "edit-mv-file-records": {
        _id: 8,
        text: "Edit MV File Records"
    },
    "edit-violation": {
        _id: 9,
        text: "Edit Violation"
    },
    "timeline": {
        _id: 77,
        text: "Timeline"
    },
    "traffic-records-menu": {
        _id: null,
        text: "Traffic Records",
        children: ["input-plate-no", "verify-traffic-record", "user-monitoring", "validate", "dispute", "reporting", "nominees-for-void", "bulk-void"],
        state: {
            opened: true
        }
    },
    "input-plate-no": {
        _id: 10,
        text: "Input Plate No."
    },
    "verify-traffic-record": {
        _id: 11,
        text: "Verify Traffic Record"
    },
    "user-monitoring": {
        _id: 12,
        text: "User Monitoring"
    },
    "validate": {
        _id: 13,
        text: "Validate"
    },
    "reporting": {
        _id: 14,
        text: "Reporting"
    },
    "nominees-for-void": {
        _id: 81,
        text: "Nominees for Void"
    },
    "bulk-void": {
        _id: 15,
        text: "Bulk Void"
    },
    "dispute": {
        _id: 78,
        text: "Dispute"
    },
    "lgu-menu": {
        _id: null,
        text: "LGU",
        children: ["approval", "rejected-record", "lto-record", "final-void"],
        state: {
            opened: true
        }
    },
    "lto-record": {
        _id: 80,
        text: "LTO Record"
    },
    "final-void": {
        _id: 82,
        text: "Final Void"
    },
    "approval": {
        _id: 16,
        text: "Approval"
    },
    "rejected-record": {
        _id: 79,
        text: "Rejected Record"
    },
    "ovr-menu": {
        _id: null,
        text: "OVR",
        children: ["edit-manifest", "print-ovr", "edit-ovr", "batch-report"],
        state: {
            opened: true
        }
    },
    "edit-manifest": {
        _id: 17,
        text: "Edit Manifest"
    },
    "print-ovr": {
        _id: 18,
        text: "Print OVR"
    },
    "edit-ovr": {
        _id: 19,
        text: "Edit OVR"
    },
    "batch-report": {
        _id: 20,
        text: "Batch Report"
    },
    "user-management-menu": {
        _id: null,
        text: "User Management",
        children: ["list-user", "add-user", "view-profile", "edit-user", "add-role", "edit-role", "lgu-access", "audit-trail", "user-report"],
        state: {
            opened: true
        }
    },
    "list-user": {
        _id: 21,
        text: "List User",
    },
    "add-user": {
        _id: 22,
        text: "Add User",
    },
    "view-profile": {
        _id: 23,
        text: "View Profile",
    },
    "edit-user": {
        _id: 24,
        text: "Edit User",
    },
    "add-role": {
        _id: 25,
        text: "Add Role"
    },
    "edit-role": {
        _id: 26,
        text: "Edit Role"
    },
    "lgu-access": {
        _id: 27,
        text: "LGU Access"
    },
    "audit-trail": {
        _id: 28,
        text: "Audit Trail"
    },
    "user-report": {
        _id: 29,
        text: "User Report"
    },
    "accounting-menu": {
        _id: null,
        text: "Accounting",
        children: ["lto-report", "payment-report", "violation-report", "lgu-approval-report"],
        state: {
            opened: true
        }
    },
    "lto-report": {
        _id: 30,
        text: "LTO Report"
    },
    "payment-report": {
        _id: 31,
        text: "Payment Report"
    },
    "violation-report": {
        _id: 32,
        text: "Violation Report"
    },
    "lgu-approval-report": {
        _id: 33,
        text: "LGU Approval Report"
    },
    "camera-menu": {
        _id: null,
        text: "Camera",
        children: ["camera-dashboard", "camera-type", "camera-process", "camera-sort", "camera-location", "camera-filter"],
        state: {
            opened: true
        }
    },
    "camera-dashboard": {
        _id: 34,
        text: "Camera Dashboard"
    },
    "camera-type": {
        _id: 35,
        text: "Camera Type"
    },
    "camera-process": {
        _id: 36,
        text: "Camera Process"
    },
    "camera-sort": {
        _id: 37,
        text: "Camera Sort"
    },
    "camera-location": {
        _id: 38,
        text: "Camera Location"
    },
    "camera-filter": {
        _id: 39,
        text: "Camera Filter"
    },
    "nov-menu": {
        _id: null,
        text: "NOV",
        children: ["search-nov"],
        state: {
            opened: true
        }
    },
    "search-nov": {
        _id: 40,
        text: "Search NOV"
    },
    "payment-menu": {
        _id: null,
        text: "Payment",
        children: ["order-of-payment", "official-receipt", "report", "recently-paid", "edit-or", "ucpb-report"],
        state: {
            opened: true
        }
    },
    "order-of-payment": {
        _id: 41,
        text: "Order of Payment"
    },
    "official-receipt": {
        _id: 42,
        text: "Official Receipt"
    },
    "report": {
        _id: 43,
        text: "Report"
    },
    "recently-paid": {
        _id: 44,
        text: "Recently Paid"
    },
    "edit-or": {
        _id: 45,
        text: "Edit OR"
    },
    "ucpb-report": {
        _id: 46,
        text: "UCPB Report"
    },
    "delivery-menu": {
        _id: null,
        text: "Delivery",
        children: ["print-novs-return-card", "add-tracking", "print-manifest", "delivery-report", "delivery-entry-report"],
        state: {
            opened: true
        }
    },
    "print-novs-return-card": {
        _id: 47,
        text: "Print NOVS / Return Card"
    },
    "add-tracking": {
        _id: 48,
        text: "Add Tracking"
    },
    "print-manifest": {
        _id: 49,
        text: "Print Manifest"
    },
    "delivery-report": {
        _id: 50,
        text: "Delivery Report"
    },
    "delivery-entry-report": {
        _id: 51,
        text: "Delivery Entry Report"
    },
    "tracking-menu": {
        _id: null,
        text: "Tracking",
        children: ["batch-received-by-philpost", "delivered-returned-rts", "update-delivery-info", "correction"],
        state: {
            opened: true
        }
    },
    "batch-received-by-philpost": {
        _id: 52,
        text: "Batch Received by Philpost"
    },
    "delivered-returned-rts": {
        _id: 53,
        text: "Delivered / Returned (RTS)"
    },
    "update-delivery-info": {
        _id: 54,
        text: "Update Delivery Info"
    },
    "correction": {
        _id: 55,
        text: "Correction"
    },
    "apprehension-menu": {
        _id: null,
        text: "Apprehension",
        children: ["manual-send-request", "manual-upload-mv", "api-logs", "apprehension-report"],
        state: {
            opened: true
        }
    },
    "manual-send-request": {
        _id: 56,
        text: "Manual Send Request"
    },
    "manual-upload-mv": {
        _id: 57,
        text: "Manual Upload MV"
    },
    "api-logs": {
        _id: 58,
        text: "API Logs"
    },
    "apprehension-report": {
        _id: 59,
        text: "Apprehension Report"
    },
    "reports-menu": {
        _id: null,
        text: "Reports",
        children: ["violation-reports", "violation-reject-report", "alarmed-delivered-report",
            "productivity-report", "lgu-violation-report", "double-ovr-report", "nov-city-completeness"],
        state: {
            opened: true
        }
    },
    "violation-reports": {
        _id: 60,
        text: "Violation Reports"
    },
    "violation-reject-report": {
        _id: 61,
        text: "Violation Reject Report"
    },
    "alarmed-delivered-report": {
        _id: 62,
        text: "Alarmed Delivered Report"
    },
    "productivity-report": {
        _id: 63,
        text: "Productivity Report"
    },
    "lgu-violation-report": {
        _id: 64,
        text: "LGU Violation Report"
    },
    "double-ovr-report": {
        _id: 65,
        text: "Double OVR Report"
    },
    "nov-city-completeness": {
        _id: 66,
        text: "NOV city Completeness"
    },
    "settings-menu": {
        _id: null,
        text: "Settings",
        children: ["violation-type", "mv-type", "mv-registration-type",
            "lgu", "nov-template", "return-card", "reject-reasons", "public-access", "camera", "whitelist"],
        state: {
            opened: true
        }
    },
    "violation-type": {
        _id: 67,
        text: "Violation Type"
    },
    "mv-type": {
        _id: 68,
        text: "MV Type"
    },
    "mv-registration-type": {
        _id: 69,
        text: "MV Registration Type"
    },
    "lgu": {
        _id: 70,
        text: "LGU"
    },
    "nov-template": {
        _id: 71,
        text: "NOV Template"
    },
    "return-card": {
        _id: 72,
        text: "Returned Card"
    },
    "reject-reasons": {
        _id: 73,
        text: "Reject Reasons"
    },
    "public-access": {
        _id: 74,
        text: "Public Access"
    },
    "camera": {
        _id: 75,
        text: "Camera"
    },
    "whitelist": {
        _id: 76,
        text: "Whitelist"
    }
};
