<script setup>
    import Layout from "@/layouts/main.vue";
    import PageHeader from "@/components/page-header";  
    import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
    import { required, helpers } from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";
    import { computed, ref, reactive } from 'vue';
    import { useStore } from 'vuex';
    import { cameraLocationStatus } from "../../../helpers/constants";

    const state = reactive({
        name: '',
        remarks: '',
        status: 'active'
    });

    const rules = {
        name: {
            required: helpers.withMessage("Location name is required", required),
        },
        remarks: {
            required: helpers.withMessage("Remarks is required", required),
        },
        status: {
            required: helpers.withMessage("Status is required", required),
        },
    }

    const store = useStore();
    const v$ = useVuelidate(rules, state);

    const loading = computed(() => store.state.globals.loading);
    const title = ref("Camera Locations");
    const items = ref([
        {
            text: "Home",
            href: "/",
        },
        {
            text: "Settings",
            href: "/camera/locations/list",
            active: true
        },
        {
            text: "Add New Camera Location",
            active: true,
        }
    ]);

    const submitted = ref(false);
    const notification = computed(() => store ? store.state.notification : null)

    function createCameraLocation() {
        submitted.value = true
        v$.value.$touch();

        if (v$.value.$invalid) return;
        store.dispatch('camera/createCameraLocation', state);
    }
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col lg-12">
            <b-overlay :show="loading">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title mb-0">Add New Camera Location</h4>
                </div>
                <div class="card-body">
                    <div v-if="notification && notification.messages.length > 0">
                        <b-alert v-for="(message, index) in notification.messages" :key="index"
                            show variant="danger" class="fade show" role="alert">
                            {{ message }}
                        </b-alert>
                    </div>

                    <form @submit.prevent="createCameraLocation" class="mx-2">
                        <div class="row">
                            <p class="fs-6 text text-decoration-underline">Camera Location Information</p>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="camera-location-name" class="form-label required">Camera Location Name</label> 
                                    <input 
                                        :class="{ 'is-invalid': submitted && v$.name.$error }"
                                        v-model="state.name" class="form-control" type="text" placeholder="Enter camera location name..." id="camera-location-name" />
                                      <div
                                        v-for="item of v$.name.$errors"
                                        :key="item.$uid"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="camera-location-remarks" class="form-label required">Remarks</label> 
                                    <input 
                                        :class="{ 'is-invalid': submitted && v$.remarks.$error }"
                                        v-model="state.remarks" class="form-control" placeholder="Enter camera location remarks..." id="camera-location-remarks" />
                                    <div
                                        v-for="item of v$.remarks.$errors"
                                        :key="item.$uid"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="lguInput" class="form-label required">Status</label>
                                    <select 
                                        :class="{ 'is-invalid': submitted && v$.status.$error }"
                                        v-model="state.status"
                                        class="form-select">
                                        <option :value="status.value" v-for="status in cameraLocationStatus" :key="status.value">
                                            {{ status.name }}
                                        </option>
                                    </select>
                                     <div
                                        v-for="item of v$.status.$errors"
                                        :key="item.$uid"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="text-end">
                                     <router-link to="/camera/locations/list" type="submit" class="btn btn-danger mr-3">
                                        <i class="ri-close-fill fs-4 mr-1 valign-middle"></i>
                                        Cancel
                                     </router-link>
                                    <button type="submit" class="btn btn-success">
                                        <i class="ri-save-2-fill fs-4 mr-1 valign-middle"></i>
                                        Add Camera Location
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </b-overlay>
        </div>
    </div>
  </Layout>
</template>